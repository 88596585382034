<template>
      <v-app class>
        <div>
          <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
    </v-alert>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>Salary History</h1>
              </div>
              <div class="row">
                <div class="col-md-8 d-flex justify-start">
                  <div class="row">
                    <div class="col-md-4">
                      <!-- <TextField type="date" :label="'From'" /> -->
                    </div>
                    <div class="col-md-4">
                     <!-- <TextField type="date" :label="'to'" /> -->
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <!-- <div class="export-container">
                    <SelectField :items="downloadFormat" v-model="formData.selectedFormat" :label="'Download Format'" :itemText="'name'" :itemValue="'id'" :returnObject="true" @input="onSelectedFormat" />
                    <Button :btnType="'Submit'" :isLoading="isDownloading" :label="'Export'" @onClick="onDownload" class="mr-2"/>
                  </div> -->
                </div>
              </div>

             <div v-if="loadingData" class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
             </div>
            <div class="scroll row">
                <div class="col-md-10 col-sm-12 ml-auto mr-auto">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <!-- <tr v-for="item in salaryHistory.salaryChanges" :key="item.index">
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th v-for="r in item.items" :key="r.index">{{r.rate}} %</th>
                                </tr> -->
                                <tr>
                                    <!-- <th>S/N</th> -->
                                    <th>Job Title</th>
                                    <th>Union Membership</th>
                                    <th v-for="item in salaryHistory.effectiveDates" :key="item.effectiveDates">{{item | formatDate}}</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row) in salaryHistory.salaryChanges" :key="row.index">
                                <!-- <td>{{index+1}}</td> -->
                                <td>
                                    {{row.jobTitle}}
                                </td>
                                <td>
                                    {{row.unionMembership}}
                                </td>
                                <td v-for="r in row.items" :key="r.index">
                                    {{r.amount}}
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
            </div>
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
// import SelectField from '@/components/ui/form/SelectField.vue'
// import Button from '@/components/ui/form/Button.vue'
import { salaryPeriodService } from '@/services'
// import moment from 'moment'

export default {
  components: {
    // Button,
    // SelectField
  },
  props: ['value'],
  data () {
    return {
      salaryHistory: [],
      alertMessage: '',
      loadingData: false,
      alert: false,
      alertType: '',
      valid: false,
      formData: {
      },
      search: '',
      personnelSearch: [],
      header: [],
      rate: []
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    getSalaryHistory () {
      this.loadingData = true
      //   const columnHearder = []
      salaryPeriodService.getAllSalaryHistroy().then(result => {
        this.salaryHistory = result.data
        this.rate = this.salaryHistory.items
        this.loadingData = false
        console.log('salary', this.salaryHistory)
      }).catch(error => {
        console.log(error)
      })
      this.loadingData = false
    }
  },
  mounted () {
    this.getSalaryHistory()
  }
}

</script>

<style scoped>
  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }

  .iconAvatar {
    width: 122px;
  }

  .text-caption {
    font-weight: 600;
    font-size: 14px !important
  }
  .sticky-col {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
  }
  .cursor {
    cursor: pointer;
  }

    .cursor:hover {
      color: blue
    }

    .cursor .tooltiptext {
      visibility: hidden;
      width: 80px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 2px 0;
      font-size: 10px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
    }
    .cursor:hover .tooltiptext {
      visibility: visible;
    }
</style>
